export const categoryInitialValues = {
  name: '',
};

export const itemsInitialValues = {
  name: '',
  price: '',
  categoryName: '',
  image: null
};
